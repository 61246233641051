import React from 'react';
import { node } from 'prop-types';
import SSRProvider from 'react-bootstrap/SSRProvider';

const propTypes = {
  element: node.isRequired,
};

const Root = ({ element }) => <SSRProvider>{element}</SSRProvider>;

Root.propTypes = propTypes;

export default Root;
